<template>
  <div v-if="filledCategories.length > 0">
    <div>
      <span class="mb-2 block font-light opacity-50">Categories</span>
      <div v-for="(category, index) in filledCategories" :key="index" class="w-full">
        <Accordion
          v-if="index < 5 || showAllCategories"
          :id="category.id"
          :title="category.name"
          :list="subcategories(category.subcategories)"
          :activeItems="activeCategories"
          headerXPadding="px-0"
          @checked="checkActiveCategory"/>
      </div>
      <button v-if="filledCategories.length > 5" class="text-sm text-violet" @click.stop="showAllCategories = !showAllCategories">
        {{ showAllCategories ? 'Show less' : `[+] ${filledCategories.length - 5} more` }}
      </button>
    </div>
    <hr class="mt-8">
  </div>
</template>

<script>
import api from '@/api';
import {mapMutations, mapState} from "vuex";
import Accordion from "@/components/filters/partials/Accordion";

export default {
  name: 'Categories',
  components: {Accordion},

  data() {
    return {
      allCategories: [],
      showAllCategories: false,
      fullCategories: [],
    }
  },

  mounted() {
    api.get(`/categories`).then(({data}) => {
      if (data.success) {
        this.allCategories = data.data
      }
    })
    api.get(`/categories?exclude=subcategories`).then(({data}) => {
      if (data.success) {
        this.fullCategories = data.data
        if (this.query.categories) {
          const catIds = this.query.categories.split(',');
          catIds.forEach(id => {
            this.checkActiveCategory(id)
          });
        }
      }
    })
  },

  methods: {
    ...mapMutations({
      toggleActiveCategory: "filters/toggleActiveCategory",
    }),

    subcategories(list) {
      return list
        .filter(item => {
          return item.counts[this.showing] > 0
        })
        .map(item => {
          return {title: item.name, id: item.id}
        })
    },

    checkActiveCategory(catId) {
      const category = this.fullCategories.find(cat => cat.id == catId);
      this.toggleActiveCategory(category);
    },
  },

  computed: {
    ...mapState({
      activeCategories: state => state.filters.activeCategories,
      query: state => state.filters.query,
      showing: state => state.filters.showing,
    }),

    filledCategories() {
      return this.allCategories.filter(category => {
        return category.counts[this.showing] > 0
      })
    }
  },

  watch: {
    activeCategories() {
      this.$emit('filter-categories')
    },
  },
}
</script>

<style scoped>
  hr {
    background: #D9DDEC;
    height: 1px;
    border: none;
    opacity: 15%;
  }
</style>
