<template>
  <div>
    <span class="mb-2 block text-sm opacity-50">Sort By</span>
    <template v-if="showing == 'guests'">
      <Radiobutton
        textColor="text-white"
        fontWeight="font-normal"
        hover="hover:text-violet"
        backgroundColor="bg-black"
        borderWidth="border"
        value="price"
        title="Price: Low to High"
        name="sortBy"
        @change="changeSort"
        :checked="query.sortBy == 'price'"
      />
      <Radiobutton
        textColor="text-white"
        fontWeight="font-normal"
        hover="hover:text-violet"
        backgroundColor="bg-black"
        borderWidth="border"
        value="-price"
        title="Price: High to Low"
        name="sortBy"
        @change="changeSort"
        :checked="query.sortBy == '-price'"
      />
    </template>
    <Radiobutton
      textColor="text-white"
      fontWeight="font-normal"
      hover="hover:text-violet"
      backgroundColor="bg-black"
      borderWidth="border"
      value="-rating"
      title="Best rated"
      name="sortBy"
      @change="changeSort"
      :checked="query.sortBy == '-rating'"
    />
    <Radiobutton
      textColor="text-white"
      fontWeight="font-normal"
      hover="hover:text-violet"
      backgroundColor="bg-black"
      borderWidth="border"
      value="-popularity"
      title="Most popular"
      name="sortBy"
      @change="changeSort"
      :checked="query.sortBy == '-popularity'"
    />
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import Radiobutton from '@/components/shared/Radiobutton';

export default {
  name: 'SortBy',
  components: {Radiobutton},

  methods: {
    ...mapMutations({
      setSortBy: "filters/setSortBy",
    }),

    changeSort(sort){
      this.setSortBy(sort)
      this.$emit('sort')
    },
  },

  computed: {
    ...mapState({
       query: state => state.filters.query,
       showing: state => state.filters.showing
    }),
  },
}
</script>
