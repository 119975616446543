<template>
  <content-loader
    :width="260"
    :height="320"
    :speed="2"
    :primaryColor="primaryColor"
    :secondaryColor="secondaryColor"
  >
    <rect x="0" y="0" rx="8" ry="8" width="260" height="260" />
    <rect x="0" y="277" rx="2" ry="2" width="210" height="18" />
    <rect x="0" y="307" rx="2" ry="2" width="110" height="12" />
  </content-loader>
</template>

<script>
  import { ContentLoader } from "vue-content-loader"
  export default {
    name: 'ProfileSkeleton',

    props: {
      primaryColor: {
        type: String,
        default: '#c9c9c9',
      },

      secondaryColor: {
        type: String,
        default: '#DEDEDE',
      },
    },

    components: {
      ContentLoader,
    },
  }
</script>
