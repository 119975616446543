<template>
  <div>
    <span class="mb-2 block text-sm opacity-50">Showing</span>
    <Radiobutton
        textColor="text-white"
        fontWeight="font-normal"
        hover="hover:text-violet"
        backgroundColor="bg-black"
        borderWidth="border"
        value="guests"
        title="Guests"
        name="showing"
        @change="changeShowing"
        :checked="showing == 'guests'"
      />
      <Radiobutton
        textColor="text-white"
        fontWeight="font-normal"
        hover="hover:text-violet"
        backgroundColor="bg-black"
        borderWidth="border"
        value="shows"
        title="Shows"
        name="showing"
        @change="changeShowing"
        :checked="showing == 'shows'"
      />

  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import Radiobutton from '@/components/shared/Radiobutton';

export default {
  name: 'Showing',
  components: {Radiobutton},

  methods: {
    ...mapMutations({
      setShowing: "filters/setShowing",
    }),

    changeShowing(showing) {
      this.setShowing(showing)
      setTimeout(() => {
        this.$emit('showing')
      }, 200)
    }
  },

  computed: {
    ...mapState({
       query: state => state.filters.query,
       showing: state => state.filters.showing,
    }),
  },
}
</script>
