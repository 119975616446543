<template>
  <div>
    <div class="bg-lightGray flex relative rounded-full">
      <multiselect
        id="ajax"
        v-model="selected"
        :options="options"
        :show-labels="false"
        :placeholder="placeholder"
        track-by="name"
        label="name"
        :allow-empty="allowEmpty"
        :multiple="multiple"
        :searchable="searchable"
        :loading="isLoading"
        :internal-search="false"
        :options-limit="50"
        :limit="10"
        :hide-selected="true"
        class="new-multiselect"
        :preserve-search="true"
        @input="$emit('selected', selected)"
        @search-change="asyncFind"
        @open="isOpen = true"
        @close="isOpen = false"
      >
        <span slot="caret" :class="{ arrow: showChevron }">
          <i v-if="!isLoading && showChevron" :class="`fas fa-chevron-${isOpen ? 'up' : 'down'}`"></i>
        </span>

        <template v-if="withImage" slot="option" slot-scope="props">
          <img class="option__image" :src="props.option.img" :alt="props.option.title" />
          <div class="option__desc">
            <span class="option__title">{{ props.option.title }}</span>
            <span class="option__small">{{ props.option.desc }}</span>
          </div>
        </template>

        <template slot="singleLabel" slot-scope="{ option }">
          <p v-if="option.desc" class="truncate" :class="{ 'mr-20': withButton }">
            <strong>{{ option.name }}</strong> {{ option.desc }}
          </p>
          <p v-else class="truncate" :class="{ 'mr-20': withButton }">
            {{ option.name }}
          </p>
        </template>

        <template slot="noOptions">
          <p>{{ noOptions }}</p>
        </template>
      </multiselect>
      <slot name="button" />
      <SearchIcon v-if="withSearchIcon" class="absolute left-0 top-0 bottom-0 h-full ml-3 w-5"/>
    </div>
    <h4 v-if="errors.length" class="text-functional-error text-xs mt-1">
      {{ errors[0] }}
    </h4>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import SearchIcon from "@/components/svgs/SearchIcon";

export default {
  name: 'BaseMultiselect',
  components: { Multiselect, SearchIcon },

  props: {
    multiple: { type: Boolean, default: false },
    allowEmpty: { type: Boolean, default: false },
    searchable: { type: Boolean, default: true },
    options: { type: Array, default: () => [] },
    initialOptions: { type: Array, default: () => [] },
    initialValues: { type: Array, required: false, default: () => [] },
    isLoading: { type: Boolean, default: false },
    placeholder: { type: String, default: 'Type to search' },
    showChevron: { type: Boolean, default: true },
    withImage: { type: Boolean, default: false },
    withButton: { type: Boolean, default: false },
    withSearchIcon: { type: Boolean, default: false },
    errors: { type: Array, required: false, default: () => [] },
    noOptions: { type: String, default: 'List is empty' },
  },

  data() {
    return {
      selected: [],
      isOpen: false,
    };
  },

  watch: {
    initialValues(initialValues) {
      this.selected = initialValues;
    },
  },

  mounted() {
    let vm = this;
    this.$nextTick().then(() => {
      if (vm.initialValues.length) {
        vm.selected = vm.initialValues;
      }
    });
  },

  methods: {
    async asyncFind(query) {
      this.$emit('search-change', query);
    },
    clearAll() {
      this.selected = [];
      // this.options = this.initialOptions;
    },
    close(selected) {
      this.isOpen = false;
      this.$emit('selected', selected);
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.arrow {
  @apply absolute right-0 pr-4 h-full flex items-center text-white;
}

.new-multiselect .multiselect__input {
  @apply text-white mb-3 !important;
}

.new-multiselect .multiselect__input::placeholder {
  @apply text-grayDark !important;
}

.new-multiselect .multiselect__tags {
  @apply bg-transparent pt-3 pr-10 pl-10 !important;
}

.new-multiselect .multiselect__content-wrapper {
  border: inherit !important;
  @apply mt-1.5 bg-lightGray rounded-xl text-white z-50 !important;
}

.new-multiselect .multiselect__placeholder {
  @apply mb-3 !important;
}

.new-multiselect .multiselect__single {
  @apply bg-transparent text-white !important;
}
</style>
