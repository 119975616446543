<template>
  <div class="w-full">
    <!-- Header-->
    <Checkbox
      v-if="!list.length"
      textColor="text-white"
      fontWeight="font-normal"
      backgroundColor="bg-black"
      hover="hover:text-violet"
      borderWidth="border"
      :key="`${title}-${id}`"
      :value="id"
      :title="title"
      name="category"
      :checked="itemIsChecked(id)"
      @on-toggle="onToggle"
    />
    <div v-else class="flex justify-between items-center cursor-pointer" :class="[headerXPadding]">
      <Checkbox
        textColor="text-white"
        fontWeight="font-normal"
        backgroundColor="bg-black"
        hover="hover:text-blue-700"
        borderWidth="border"
        :value="id"
        :title="title"
        name="category"
        :checked="itemIsChecked(id)"
        @on-toggle="onToggle"
      />
      <button v-if="list.length" @click.prevent="toggle">
        <i :class="`fas fa-chevron-${open ? 'up' : 'down'}`"></i>
      </button>
    </div>
    <!-- Content-->
    <transition @before-enter="beforeEnter" @enter="enter">
      <div v-if="open" :class="[contentXPadding]">
        <Checkbox
          v-for="(item, index) in list"
          :key="`item-${index}`"
          textColor="text-white"
          fontWeight="font-normal"
          backgroundColor="bg-black"
          fontSize="text-sm"
          hover="hover:text-violet"
          borderWidth="border"
          :value="item.id"
          :title="item.title"
          name="category"
          :checked="itemIsChecked(item.id)"
          @on-toggle="onToggle"
        />
      </div>
    </transition>
  </div>
</template>
<script>
import anime from "animejs";
import Checkbox from '@/components/shared/Checkbox';

export default {
  name: 'Accordion',
  components: {Checkbox},
  props: {
    title: String,
    id: Number,
    list: Array,
    activeItems: Array,
    headerXPadding: {
      type: String,
      default: 'px-3'
    },
    contentXPadding:{
      type: String,
      default: 'px-6'
    }
  },

  data() {
    return {
      open: false
    }
  },

  methods: {
    toggle() {
      this.open = !this.open;
    },
    beforeEnter(el) {
      el.style.maxHeight = 0
    },
    enter(el) {
      anime({
        targets: el,
        maxHeight: el.scrollHeight + 20,
        duration: 600,
      });
    },

    itemIsChecked(id) {
      return this.activeItems.findIndex(item => item.id == id) >= 0
    },

    onToggle(value) {
      this.$emit('checked', parseInt(value))
    }
  },
  
  watch: {
    activeItems() {
      this.open = this.itemIsChecked(this.id);
      if (!this.open) {
        let open = false;
        this.list.forEach(item => {
          if (this.itemIsChecked(item.id)) {
            open = true;
          }
        });
        this.open = open;
      }
    }
  },
}
</script>
