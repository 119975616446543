<template>
  <div class="min-h-screen bg-black text-white pt-12 px-6 lg:px-0">
    <div class="container mx-auto grid col-span-1 lg:grid-cols-12 gap-2 lg:gap-10">
      <!--LEFT COL (filters)-->
      <div class="lg:col-span-3 ">

                <div class="flex items-center justify-between mb-10 sm:h-12">
                    <div class="w-full">
                        <div class="relative mr-6 w-full mb-5 lg:mb-0 flex-shrink-0 flex">
                            <h3 class="text-2xl font-bold">Browse</h3>
                        </div>
                    </div>
                    <!--Modal (ONLY MOBILE)-->
                    <Modal overlay="bg-black" v-if="mobile" :withCloseButton="false">
                        <template>
                            <button class="rounded-full px-4 py-3 bg-pureBlack text-white text-sm lg:hidden">
                                Sort & Filter
                                <span v-if="activeFiltersCount > 0">({{ activeFiltersCount }})</span>
                            </button>
                        </template>
                        <template v-slot:content="{ close }">
                            <div class="w-full h-full pt-8 overflow-y-scroll">
                                <div class="flex justify-between px-3">
                                    <h3 class="text-xl font-bold text-white">Filters</h3>
                                    <button class="text-white" @click.stop="close">
                                        <CrossIcon @click.stop="close" color="white" class="w-8 h-8" />
                                    </button>
                                </div>
                                <div class="relative mb-16">
                                    <Filters @fetch="fetch" />
                                    <div class="fixed bottom-0 w-full flex justify-between text-white bg-black">
                                        <button @click="clearFilters"
                                            class="w-1/2 rounded-full py-3 bg-pureBlack mr-4 text-sm font-bold">Clear</button>
                                        <button @click="close"
                                            class="w-1/2 rounded-full py-3 bg-indigo-gradiant text-sm font-bold">Apply</button>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </Modal>
                </div>
                <!--Filters-->
                <div v-if="!mobile" class="hidden lg:grid grid-cols-1 gap-y-10">
                    <Filters @fetch="fetch" />
                </div>
            </div>

            <!--RIGHT COL (results)-->
            <div class="lg:col-span-9">
                <div class=" w-full flex flex-wrap lg:flex-no-wrap items-center mb-10">
                    <!--Search input-->
                    <div class="relative mr-6 w-full lg:w-6/12 mb-5 lg:mb-0 flex-shrink-0 flex">
                        <BaseMultiselect class="w-full lg:w-10/12" placeholder="Search..." :options="options"
                            no-options="Type to search" :with-search-icon="true"
                            :initialValues="query.search ? [{ name: query.search }] : null"
                            @search-change="searchSuggestions" @selected="goToProfile" />
                        <button class="ml-3 bg-indigo-gradiant text-white rounded-full px-8 py-2.5"
                            :disabled="query.search == ''" @click.prevent="search">Browse</button>
                    </div>
                    <!--Filters-->
                    <div class="flex flex-wrap">
                        <!--Badge-->
                        <div v-for="(filter, index) in activeFilters" :key="index"
                            class="rounded-full bg-purple-50 flex items-center px-5 py-2">
                            <span class="mr-3 text-sm">{{ filter }}</span>
                            <button @click.prevent="clearSearch()">
                                <CrossIcon color="white" :classes="'w-4 h-4'" />
                            </button>
                        </div>
                    </div>
                </div>

                <div :class="{ 'opacity-50': loading }" ref="profiles">
                    <div v-if="allGuests.length"
                        class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 mb-12">
                        <Item :profile="guest" v-for="(guest, index) in allGuests"
                            :key="`${showing}-${index}-${guest.id}`" @toggled-vip="fetch" />

                        <template v-if="loading && !allGuests.length">
                            <profile-skeleton v-for="i in 8" primary-color="#F5F5F5" secondary-color="#FAFAFA"
                                :key="`skeleton-${i}`" />
                        </template>
                    </div>

          <div v-else class="flex items-center justify-center flex-col mt-20 sm:mt-32">
            <div>
              <BrowseEmptySvg />
            </div>

            <h5 class="text-white text-xl mt-12">
              Your search returned no matches
            </h5>
          </div>
        </div>

                <div v-if="pagination.total_pages > 1" class="pagination my-12 flex sm:justify-end">
                    <nav class="h-10 flex items-center text-white w-full sm:w-auto" role="pagination">
                        <router-link :to="prevPageRoute"
                            class="opacity-40 w-full sm:w-10 h-full flex items-center justify-center border border-guestio-gray-100 rounded-l-xl hover:opacity-60 transition ease-in-out duration-150">
                            <ChevronLeftAltIcon class="h-5" />
                        </router-link>

                        <router-link v-for="page in paginationRange" :key="page" :to="pageRoute(page)"
                            :class="{ 'opacity-40': page !== pagination.current_page }"
                            class=" w-full sm:w-10 h-full flex items-center justify-center border">
                            {{ page }}
                        </router-link>

                        <router-link v-if="pagination.links.next" :to="nextPageRoute"
                            class="opacity-40  w-full sm:w-10 h-full flex items-center justify-center border border-guestio-gray-100 rounded-r-xl hover:opacity-60 transition ease-in-out duration-150">
                            <ChevronRightAltIcon class="h-5" />
                        </router-link>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { debounce, startCase } from 'lodash'
import { mapMutations, mapState, mapActions } from "vuex"
import Item from "@/components/home/Item";
import anime from "animejs";
import ProfileSkeleton from '@/components/shared/ProfileSkeleton'
import Filters from '@/components/shared/Filters'
import CrossIcon from "@/components/svgs/CrossIcon"
import ChevronLeftAltIcon from "@/components/svgs/ChevronLeftAltIcon"
import ChevronRightAltIcon from "@/components/svgs/ChevronRightAltIcon"
import Modal from "@/components/shared/Modal";
import BrowseEmptySvg from "@/components/svgs/BrowseEmptySvg";
import isMobile from "is-mobile";
import { range } from 'lodash'
import BaseMultiselect from '@/components/shared/BaseMultiselect';

export default {
  name: "Browse",
  components: {
    Modal,
    Item,
    Filters,
    CrossIcon,
    ProfileSkeleton,
    ChevronLeftAltIcon,
    ChevronRightAltIcon,
    BrowseEmptySvg,
    BaseMultiselect,
  },

  data() {
    return {
      allGuests: [],
      currentPage: parseInt(this.$route.query.page) || 1,
      infiniteId: +new Date(),
      mobile: isMobile(),
      fetchAction: 'guest/guests',
      activeFiltersCount: 0,
      loading: true,
      options: [],
    }
  },

  // beforeRouteEnter(to, from, next) {
  //   next(vm => {

  //   })
  // },

  async created() {
    this.$store.dispatch('guest/fetchGuestPriceStats')

    await this.initFiltersFromQuery()

    await this.fetchBookingOptions();

    this.loadProfiles()
  },

  mounted() {
    anime({
      targets: '.itemCard',
      duration: 700,
      opacity: [0, 1],
      delay: anime.stagger(100)
    });

    // this.loadProfiles()
  },
  computed: {
    ...mapState({
      guests: state => state.guest.guests,
      lowestPrice: state => state.guest.priceStats.cheapest_guest_price,
      highestPrice: state => state.guest.priceStats.expensive_guest_price,
      activeCategories: state => state.filters.activeCategories,
      allGuestTags: state => state.tags.allGuestTags,
      query: state => state.filters.query,
      filters: state => state.filters,
      showing: state => state.filters.showing,
      activeFilters: state => state.filters.activeFilters,
    }),

    pagination() {
      if (this.showing === 'guests') {
        return this.$store.getters['guest/pagination']
      }

      return this.$store.getters['shows/pagination']
    },

    urlQuery() {
      return this.$route.query
    },

    prevPageRoute() {
      if (! this.pagination.links.previous) {
        return this.pageRoute(1)
      }

      return this.pageRoute(this.pagination.current_page - 1)
    },

    nextPageRoute() {
      if (! this.pagination.links.next) {
        return this.pageRoute(this.pagination.total_pages)
      }

      return this.pageRoute(this.pagination.current_page + 1)
    },

    paginationRange() {
      if (this.pagination.total_pages <= 5) {
        return this.pagination.total_pages
      }

      if (this.pagination.current_page > 3) {
        const from = this.pagination.current_page - 2;
        const nextPagesAmount = this.pagination.total_pages - this.pagination.current_page;
        const to = nextPagesAmount > 2 ? 3 : nextPagesAmount;
        return range(from, this.pagination.current_page + to + 1);
      }

      return 5
    }
  },

  methods: {
    ...mapMutations({
      addActiveFilter: "filters/addActiveFilter",
      setSortBy: "filters/setSortBy",
      setRating: "filters/setRating",
      setShowing: "filters/setShowing",
      setPriceRange: "filters/setPriceRange",
      setActivePrices: "filters/setActivePrices",
      setBookingOptions: "filters/setBookingOptions",
      setActiveBookingOptions: "filters/setActiveBookingOptions",
      setTags: "filters/setTags",
      setSearch: "filters/setSearch",
      setCategories: "filters/setCategories",
      setFeatured: "filters/setFeatured",
      setIsGuestioTalent: "filters/setIsGuestioTalent",
      setPremium: "filters/setPremium",
      clearActiveFilters: "filters/clearActiveFilters",
      removeSearch: "filters/removeSearch",
    }),

    ...mapActions({
      fetchBookingOptions: 'filters/fetchBookingOptions',
    }),

    pageRoute(pageNo) {
      return {
        name: 'Browse',
        query: {
          ...this.urlQuery,
          page: pageNo,
        },
      }
    },

    toggle() {
      this.isOpen = !this.isOpen
    },

    clearSearch() {
      this.removeSearch();
      this.$router.push({ query: this.query })
      this.fetch();
    },

    clearFilters() {
      this.clearActiveFilters();
      this.fetch();
    },

    search() {
      if (this.query.search == '') {
        this.removeSearch();
      }
      this.$router.push({ query: this.query })
      this.fetch()
    },

    searchSuggestions(suggestionsQuery) {
      if (suggestionsQuery) {
        this.query.search = suggestionsQuery;
        this.fetchSuggestions(suggestionsQuery);
      }
    },

    fetchSuggestions: debounce(function(suggestionsQuery) {
      this.loading = true;
      this.$store.dispatch(this.fetchAction, { name: suggestionsQuery, page:1 })
        .then(({data}) => {
          if (data.length) {
            this.options = data.map(profile => {
              return {
                name: profile.name,
                type: profile.type,
                id: profile.id,
              };
            });
          } else {
            // $state.complete();
          }
        })
        .finally(() => this.loading = false)
    }, 750),

    loadProfiles() {
      this.loading = true;

      let params = {page: this.currentPage};

      if (this.query.tags) {
        params.tags = this.query.tags;
      }

      if (this.query.categories) {
        params.categories = this.query.categories.split(',');
      }

      if (this.query.prices) {
        params.prices = this.query.prices;
      }

      if (this.query.options) {
        params.options = this.query.options;
      }

      if (this.query.search) {
        params.name = this.query.search;
        this.addActiveFilter({search: `"${this.query.search}"`});
      }

      if (this.query.rating) {
        params.rating = this.query.rating;
      }

      if (this.query.sortBy) {
        params.sortBy = this.query.sortBy;
      }

      if (this.query.featured) {
        params.featured = this.query.featured;
      }

      if (this.query.isGuestioTalent) {
        params.isGuestioTalent = this.query.isGuestioTalent;
      }

      if (this.query.premium) {
        params.premium = this.query.premium;
      }

      this.$store.dispatch(this.fetchAction, params)
        .then(({data}) => {
          if (data.length) {
            // this.currentPage += 1;
            this.allGuests = data;
            // $state.loaded();
          } else {
            // $state.complete();
          }
        })
        .finally(() => this.loading = false)
    },

    fetch() {
      this.currentPage = 1;
      this.allGuests = [];
      this.loadProfiles();
    },

    async initFiltersFromQuery() {
      this.clearActiveFilters()

      if (this.$route.query.page) {
        this.currentPage = this.$route.query.page
      }
      if (this.$route.query.showing) {
        this.setShowing(this.$route.query.showing)
        this.fetchAction = this.$route.query.showing == 'shows' ? 'shows/fetch' : 'guest/guests'
      }
      if (this.$route.query.sortBy) {
        this.setSortBy(this.$route.query.sortBy)
      }
      if (this.$route.query.rating) {
        this.setRating(this.$route.query.rating)
      }
      if (this.$route.query.prices !== undefined) {
        let prices = this.$route.query.prices.toString()
        this.setPriceRange(prices)
        this.setActivePrices(prices.split(','));
      }
      if (this.$route.query.options !== undefined) {
        let options = this.$route.query.options.toString()
        this.setBookingOptions(options)
        this.setActiveBookingOptions(options.split(','));
      }
      if (this.$route.query.tags) {
        this.setTags(this.$route.query.tags)
      }
      if (this.$route.query.search) {
        this.setSearch(this.$route.query.search)
      }
      if (this.$route.query.categories) {
        this.setCategories(this.$route.query.categories)
      }
      if (this.$route.query.premium) {
        this.setPremium(1)
      }
      if (this.$route.query.featured == 1) {
        this.setFeatured(1)
      }
      if (this.$route.query.isGuestioTalent == 1) {
        this.setIsGuestioTalent(1)
      }

      Promise.resolve(true)
    },

    goToProfile(profile) {
      const params = {};
      if (profile.type == 'guest') {
        params['guestId'] = profile.id;
      } else {
        params['showId'] = profile.id;
      }
      this.$router.push({name: startCase(profile.type), params: params })
    },
  },

  watch: {
    showing(showing){
      this.fetchAction = showing == 'shows' ? 'shows/fetch' : 'guest/guests'
    },

    filters:{
      immediate: true,
      deep: true,
      handler(filters){
        this.activeFiltersCount = Object.keys(filters.query).length
      }
    },

    $route(to, from) {
      if (to.query.page === from.query.page) {
        return
      }

      this.currentPage = to.query.page
      this.$refs.profiles.scrollIntoView({behavior: 'smooth'})
      this.loadProfiles()
    }
  },
}
</script>

<style scoped>
  @media (min-width: 1920px) {
    .container{
      max-width: 90%;
    }
  }
</style>

