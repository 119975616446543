<template>
  <div>
    <span class="mb-2 block font-light opacity-50">Price</span>
    <div v-for="(range, index) in ranges" :key="index">
      <Checkbox
        textColor="text-white"
        fontWeight="font-normal"
        hover="hover:text-violet"
        backgroundColor="bg-black"
        borderWidth="border"
        :value="range"
        :title="title(range)"
        name="price"
        :checked="isItemChecked(range)"
        @on-toggle="filterByPriceRange"
      />
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import Checkbox from '@/components/shared/Checkbox';

export default {
  name: 'Price',
  components: {Checkbox},

  data() {
    return {
      ranges: [
        [0], [1,50], [51,100], [101, 200], [201, 400], [401]
      ]
    }
  },

  methods: {
    ...mapMutations({
      toggleActivePriceRange: "filters/toggleActivePriceRange",
      setSortBy: "filters/setSortBy",
    }),

    filterByPriceRange(range) {
      this.toggleActivePriceRange(range.replace(',','-'))

      if (this.activePrices.length && ! this.query.sortBy) {
        this.setSortBy('-price')
      }

      setTimeout(() => {
        this.$emit('filter-price-range')
      })
    },

    isItemChecked(range){
      if (range[0] === 401) {
        range = [401, 99999]
      }
      return this.activePrices.includes(range.join('-'))
    },

    title(range){
      range.length > 1 ? `$${range[0]} - $${range[1]}` : 'Free'
      if (range.length > 1) {
        return `$${range[0]} - $${range[1]}`
      }
      return range[0] == 0 ? 'Free' : '$401+'
    }
  },

  computed: {
    ...mapState({
       activePrices: state => state.filters.activePrices,
       query: state => state.filters.query
    }),
  }
}
</script>
