<template>
  <div>
    <span class="mb-2 block text-sm opacity-50">Rating</span>
    <Radiobutton
      v-for="(item, index) in 4" :key="item"
      textColor="text-white"
      fontWeight="font-normal"
      hover="hover:text-violet"
      backgroundColor="bg-black"
      borderWidth="border"
      :value="4 - index"
      name="rating"
      @change="filterByRating"
      :checked="query.rating == 4 - index"
    >
      <template slot="content">
        <div class="ml-3 text-sm flex items-start">
          <ProfileRating :rating="4 - index" :starSize="18" activeColor="#B503D0" inactiveColor="#413F51" :padding="0" class="-mt-1" :borderWidth="2"/>
          <span class="ml-2">& up</span>
        </div>
      </template>
    </Radiobutton>

    <div v-if="activeRating && activeRating.length" class="mt-4">
      <button class="text-pink-500 text-sm font-mediun" type="button" @click.prevent="filterByRating('')">
        Reset Rating
      </button>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import Radiobutton from '@/components/shared/Radiobutton';
import ProfileRating from "@/components/shared/ProfileRating";

export default {
  name: 'Rating',
  components: {Radiobutton, ProfileRating},

  methods: {
    ...mapMutations({
      setRating: "filters/setRating",
    }),

    filterByRating(rating){
      this.setRating(rating)
      this.$emit('filter-rating')
    },
  },

  computed: {
    ...mapState({
       query: state => state.filters.query,
       activeRating: state => state.filters.activeRating,
    }),
  },
}
</script>
