<template>
  <div>
    <span class="mb-2 block text-sm opacity-50">Tags</span>
    <div class="bg-lightGray px-3 py-2 rounded-full w-full items-center flex overflow-hidden my-3">
      <div class="flex flex-grow items-center">
        <button class="mr-3 text-white">
          <SearchIcon color="white" class="w-4 h-4"/>
        </button>
        <input
          style="outline: none;"
          v-model="searchQuery"
          type="text"
          class="w-full mr-3 text-white placeholder-white placeholder-opacity-50 bg-transparent font-light text-xs"
          placeholder="Search tags..."/>
      </div>
    </div>
    <div class="h-56 overflow-auto">
      <div v-for="(tag, index) in shownTags" :key="`tag-${index}`">
        <Checkbox
          v-if="index < 7 || showAllTags"
          :value="tag"
          :title="tag"
          textColor="text-white"
          fontWeight="font-normal"
          hover="hover:text-violet"
          backgroundColor="bg-black"
          borderWidth="border"
          name="tag"
          :checked="isTagChecked(tag)"
          @on-toggle="onToggleTag"
        />
        <span v-if="!shownTags.length" class="text-xs">No tags found</span>
      </div>
    </div>
    
    <button v-if="shownTags.length" class="text-sm text-violet" @click.stop="showAllTags = !showAllTags">
      {{ showAllTags ? 'Show less' : `[+] ${Object.keys(shownTags).length - 5} more` }}
    </button>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import Checkbox from "@/components/shared/Checkbox";
import SearchIcon from "@/components/svgs/SearchIcon";

export default {
  name: 'Tags',
  components: {Checkbox, SearchIcon},

  props: {
    tags: Object
  },

  data() {
    return {
      searchQuery: '',
      showAllTags: false,
    }
  },

  methods: {
    ...mapMutations({
      toggleTag: "filters/toggleTag",
    }),

    onToggleTag(tag) {
      this.toggleTag(tag)
      this.$emit('filter-tags')
    },

    isTagChecked(tag) {
      return this.activeTags.findIndex(t => t == tag) >= 0
    }
  },

  computed: {
    ...mapState({
       query: state => state.filters.query,
       showing: state => state.filters.showing,
    }),

    activeTags() {
      return this.query.tags ? this.query.tags.split(',') : []
    },

    shownTags() {
      let ret = [];

      const activeLowerCase = this.activeTags.map(c => c.toLowerCase());
      for (const i in activeLowerCase) {
        if (activeLowerCase[i].includes(this.searchQuery.toLowerCase())) {
          ret.push(this.activeTags[i])
        }
      }

      for (const catIdx in this.tags) {
        if (this.tags[catIdx].toLowerCase().includes(this.searchQuery.toLowerCase()) &&
          !this.activeTags.includes(this.tags[catIdx])) {
          ret.push(this.tags[catIdx])
        }
      }
      return ret;
    }
  },
}
</script>

<style scoped>
::-webkit-scrollbar {
    width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(0,0,0,0.3); 
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}
</style>